import { baseUrl } from '@/utils/env'
import { AxiosResponse } from 'axios'
import { HttpService } from '@/services/common/HttpService'
import { TableViewParameters, TableViewResult } from '@/models/TableView'
import { Lead } from '@/models/dto'

const httpService: HttpService = new HttpService()

export default {
  getCharterUpLeads(params: TableViewParameters): Promise<AxiosResponse<TableViewResult<Lead>>> {
    const { pageSize = 10, page = 1, sorts = null, filters = null } = params

    let query = `page=${page}&pageSize=${pageSize}`

    if (sorts) {
      query += `&${sorts}`
    }
    if (filters) {
      query += `&${filters}`
    }
    query = encodeURI(query)

    const url = `https://${baseUrl()}/leads/charterup?${query}`
    return httpService.get(url)
  },
}
