<template>
  <BaseTableWrapper
    :special-text-formatting="specialTextFormatting"
    :table-init="tableInit"
  >
    <h1 class="page-header">Web Forms</h1>
  </BaseTableWrapper>
</template>

<script>
import BaseTableWrapper from '@/components/BaseTableWrapper.vue'
import charterUpLeads from '@/services/charterUpLeads'
import { DateTime } from 'luxon'
import { phoneFormatFilter } from '@/utils/phone'

const columns = [
  {
    _t_id: '4290a70e',
    prop: 'charterUPLeadId',
    text: 'ID',
    sort: true,
    filter: true,
    filterType: 'contains',
    type: 'text',
    sortProp: 'charterUPLeadId',
    defaultSort: true,
    detail: false,
  },
  {
    _t_id: '4290a70f',
    prop: 'formTypeLabel',
    text: 'Form Type',
    sort: true,
    filter: true,
    filterType: 'contains',
    type: 'text',
    sortProp: 'formTypeLabel',
    detail: false,
  },
  {
    _t_id: '4290aede',
    prop: 'createdOn',
    text: 'Created On',
    sort: true,
    filter: false,
    type: 'text',
    sortProp: 'createdOn',
    computedText: (item) =>
      item === null ? '' : DateTime.fromISO(item).toFormat('MM/dd/yyyy'),
    detail: false,
  },
  {
    _t_id: '4290a70a',
    prop: 'name',
    text: 'Name',
    sort: true,
    filter: true,
    filterType: 'contains',
    type: 'text',
    sortProp: 'name',
    detail: false,
  },
  {
    _t_id: '4290a9d4',
    prop: 'phone',
    text: 'Phone',
    sort: true,
    filter: true,
    filterType: 'contains',
    type: 'text',
    sortProp: 'phone',
    detail: false,
  },
  {
    _t_id: '4290ab46',
    prop: 'email',
    text: 'Email',
    sort: true,
    filter: true,
    filterType: 'contains',
    type: 'text',
    sortProp: 'email',
    detail: false,
  },

  {
    _t_id: '4290ac2c',
    prop: 'company',
    text: 'Company',
    sort: true,
    filter: true,
    filterType: 'contains',
    type: 'text',
    sortProp: 'company',
    detail: false,
  },

  {
    _t_id: '4290ad12',
    prop: 'dotNumber',
    text: 'DOT',
    sort: true,
    filter: true,
    filterType: 'contains',
    type: 'text',
    sortProp: 'dotNumber',
    defaultHidden: true,
    detail: false,
  },
  {
    _t_id: '4290ae16',
    prop: 'state',
    text: 'State',
    sort: true,
    filter: true,
    filterType: 'contains',
    type: 'text',
    sortProp: 'state',
    detail: false,
  },
  {
    _t_id: '4290ae00',
    prop: 'address',
    text: 'Address',
    sort: true,
    filter: true,
    filterType: 'contains',
    type: 'text',
    sortProp: 'address',
    defaultHidden: true,
    detail: false,
  },
  {
    _t_id: '4290ae01',
    prop: 'city',
    text: 'City',
    sort: true,
    filter: true,
    filterType: 'contains',
    type: 'text',
    sortProp: 'city',
    defaultHidden: true,
    detail: false,
  },
  {
    _t_id: '4290ae047',
    prop: 'bestContactTime',
    text: 'Best Contact Time',
    sort: true,
    filter: true,
    filterType: 'contains',
    type: 'text',
    sortProp: 'bestContactTime',
    computedText: (item) =>
      item === null ? '' : DateTime.fromISO(item).toFormat('MM/dd/yyyy'),
    detail: false,
  },
  {
    _t_id: '4290ae02',
    prop: 'field1',
    text: 'Title',
    sort: true,
    filter: true,
    filterType: 'contains',
    type: 'text',
    sortProp: 'field1',
    defaultHidden: true,
    detail: false,
  },
  {
    _t_id: '4290ae03',
    prop: 'field2',
    text: 'State Of Pick Up',
    sort: true,
    filter: true,
    filterType: 'contains',
    type: 'text',
    sortProp: 'field2',
    defaultHidden: true,
    detail: false,
  },
  {
    _t_id: '4290ae04',
    prop: 'field3',
    text: 'Reason For Financing',
    sort: true,
    filter: true,
    filterType: 'contains',
    type: 'text',
    sortProp: 'field3',
    defaultHidden: true,
    detail: false,
  },
  {
    _t_id: '4290ae05',
    prop: 'field4',
    text: 'field4',
    sort: true,
    filter: true,
    filterType: 'contains',
    type: 'text',
    sortProp: 'field4',
    defaultHidden: true,
  },
  {
    _t_id: '4290ae06',
    prop: 'field5',
    text: 'field5',
    sort: true,
    filter: true,
    filterType: 'contains',
    type: 'text',
    sortProp: 'field5',
    defaultHidden: true,
  },
  {
    _t_id: '4290ae07',
    prop: 'field6',
    text: 'field6',
    sort: true,
    filter: true,
    filterType: 'contains',
    type: 'text',
    sortProp: 'field6',
    defaultHidden: true,
  },
  {
    _t_id: '4290ae08',
    prop: 'field7',
    text: 'field7',
    sort: true,
    filter: true,
    filterType: 'contains',
    type: 'text',
    sortProp: 'field7',
    defaultHidden: true,
  },
  {
    _t_id: '4290ae09',
    prop: 'field8',
    text: 'field8',
    sort: true,
    filter: true,
    filterType: 'contains',
    type: 'text',
    sortProp: 'field8',
    defaultHidden: true,
  },
  {
    _t_id: '4290ae10',
    prop: 'field9',
    text: 'field9',
    sort: true,
    filter: true,
    filterType: 'contains',
    type: 'text',
    sortProp: 'field9',
    defaultHidden: true,
  },
  {
    _t_id: '4290ae11',
    prop: 'field10',
    text: 'field10',
    sort: true,
    filter: true,
    filterType: 'contains',
    type: 'text',
    sortProp: 'field10',
    defaultHidden: true,
  },
  {
    _t_id: '4290ae12',
    prop: 'field11',
    text: 'field11',
    sort: true,
    filter: true,
    filterType: 'contains',
    type: 'text',
    sortProp: 'field11',
    defaultHidden: true,
  },
  {
    _t_id: '4290ae13',
    prop: 'field12',
    text: 'field12',
    sort: true,
    filter: true,
    filterType: 'contains',
    type: 'text',
    sortProp: 'field12',
    defaultHidden: true,
  },
  {
    _t_id: '4290ae14',
    prop: 'field13',
    text: 'field13',
    sort: true,
    filter: true,
    filterType: 'contains',
    type: 'text',
    sortProp: 'field13',
    defaultHidden: true,
  },
  {
    _t_id: '4290ae15',
    prop: 'field14',
    text: 'field14',
    sort: true,
    filter: true,
    filterType: 'contains',
    type: 'text',
    sortProp: 'field14',
    defaultHidden: true,
  },
  {
    _t_id: '4290ae16',
    prop: 'field15',
    text: 'field15',
    sort: true,
    filter: true,
    filterType: 'contains',
    type: 'text',
    sortProp: 'field15',
    defaultHidden: true,
  },
  {
    _t_id: '4290ae17',
    prop: 'field16',
    text: 'field16',
    sort: true,
    filter: true,
    filterType: 'contains',
    type: 'text',
    sortProp: 'field16',
    defaultHidden: true,
  },
  {
    _t_id: '4290ae18',
    prop: 'field17',
    text: 'field17',
    sort: true,
    filter: true,
    filterType: 'contains',
    type: 'text',
    sortProp: 'field17',
    defaultHidden: true,
  },
  {
    _t_id: '4290ae19',
    prop: 'field18',
    text: 'field18',
    sort: true,
    filter: true,
    filterType: 'contains',
    type: 'text',
    sortProp: 'field18',
    defaultHidden: true,
  },
  {
    _t_id: '4290ae20',
    prop: 'field19',
    text: 'field19',
    sort: true,
    filter: true,
    filterType: 'contains',
    type: 'text',
    sortProp: 'field19',
    defaultHidden: true,
  },
  {
    _t_id: '4290ae21',
    prop: 'field20',
    text: 'field20',
    sort: true,
    filter: true,
    filterType: 'contains',
    type: 'text',
    sortProp: 'field20',
    defaultHidden: true,
  },
  {
    _t_id: '4290ae22',
    prop: 'field21',
    text: 'field21',
    sort: true,
    filter: true,
    filterType: 'contains',
    type: 'text',
    sortProp: 'field21',
    defaultHidden: true,
  },
  {
    _t_id: '4290ae23',
    prop: 'field22',
    text: 'field22',
    sort: true,
    filter: true,
    filterType: 'contains',
    type: 'text',
    sortProp: 'field22',
    defaultHidden: true,
  },
  {
    _t_id: '4290ae24',
    prop: 'field23',
    text: 'field23',
    sort: true,
    filter: true,
    filterType: 'contains',
    type: 'text',
    sortProp: 'field23',
    defaultHidden: true,
  },
  {
    _t_id: '4290ae25',
    prop: 'field24',
    text: 'field24',
    sort: true,
    filter: true,
    filterType: 'contains',
    type: 'text',
    sortProp: 'field24',
    defaultHidden: true,
  },
  {
    _t_id: '4290ae26',
    prop: 'field25',
    text: 'field25',
    sort: true,
    filter: true,
    filterType: 'contains',
    type: 'text',
    sortProp: 'field25',
    defaultHidden: true,
  },
  {
    _t_id: '4290ae27',
    prop: 'field26',
    text: 'field26',
    sort: true,
    filter: true,
    filterType: 'contains',
    type: 'text',
    sortProp: 'field26',
    defaultHidden: true,
  },
  {
    _t_id: '4290ae28',
    prop: 'field27',
    text: 'field27',
    sort: true,
    filter: true,
    filterType: 'contains',
    type: 'text',
    sortProp: 'field27',
    defaultHidden: true,
  },
  {
    _t_id: '4290ae29',
    prop: 'field28',
    text: 'field28',
    sort: true,
    filter: true,
    filterType: 'contains',
    type: 'text',
    sortProp: 'field28',
    defaultHidden: true,
  },
  {
    _t_id: '4290ae30',
    prop: 'field29',
    text: 'field29',
    sort: true,
    filter: true,
    filterType: 'contains',
    type: 'text',
    sortProp: 'field29',
    defaultHidden: true,
  },
  {
    _t_id: '4290ae31',
    prop: 'field30',
    text: 'field30',
    sort: true,
    filter: true,
    filterType: 'contains',
    type: 'text',
    sortProp: 'field30',
    defaultHidden: true,
  },
  {
    _t_id: '4290ae32',
    prop: 'field31',
    text: 'field31',
    sort: true,
    filter: true,
    filterType: 'contains',
    type: 'text',
    sortProp: 'field31',
    defaultHidden: true,
  },
  {
    _t_id: '4290ae33',
    prop: 'field32',
    text: 'Bus Bookings Per Year',
    sort: true,
    filter: true,
    filterType: 'eq',
    type: 'Number',
    sortProp: 'field32',
    defaultHidden: true,
    detail: false,
  },
  {
    _t_id: '4290ae34',
    prop: 'field33',
    text: 'Passenger Count',
    sort: true,
    filter: true,
    filterType: 'eq',
    type: 'Number',
    sortProp: 'field33',
    defaultHidden: true,
    detail: false,
  },
  {
    _t_id: '4290ae35',
    prop: 'field34',
    text: 'Amount',
    sort: true,
    filter: true,
    filterType: 'contains',
    type: 'text',
    sortProp: 'field34',
    defaultHidden: true,
    detail: false,
  },
  {
    _t_id: '4290ae36',
    prop: 'field35',
    text: 'field35',
    sort: true,
    filter: true,
    filterType: 'contains',
    type: 'text',
    sortProp: 'field35',
    defaultHidden: true,
  },
  {
    _t_id: '4290ae37',
    prop: 'field36',
    text: 'field36',
    sort: true,
    filter: true,
    filterType: 'contains',
    type: 'text',
    sortProp: 'field36',
    defaultHidden: true,
  },
  {
    _t_id: '4290ae38',
    prop: 'field37',
    text: 'field37',
    sort: true,
    filter: true,
    filterType: 'contains',
    type: 'text',
    sortProp: 'field37',
    defaultHidden: true,
  },
  {
    _t_id: '4290ae39',
    prop: 'field38',
    text: 'field38',
    sort: true,
    filter: true,
    filterType: 'contains',
    type: 'text',
    sortProp: 'field38',
    defaultHidden: true,
  },
  {
    _t_id: '4290ae40',
    prop: 'field39',
    text: 'field39',
    sort: true,
    filter: true,
    filterType: 'contains',
    type: 'text',
    sortProp: 'field39',
    defaultHidden: true,
  },
  {
    _t_id: '4290ae41',
    prop: 'field40',
    text: 'field40',
    sort: true,
    filter: true,
    filterType: 'contains',
    type: 'text',
    sortProp: 'field40',
    defaultHidden: true,
  },
  {
    _t_id: '4290ae42',
    prop: 'field41',
    text: 'field41',
    sort: true,
    filter: true,
    filterType: 'contains',
    type: 'text',
    sortProp: 'field41',
    defaultHidden: true,
  },
  {
    _t_id: '4290ae43',
    prop: 'field42',
    text: 'field42',
    sort: true,
    filter: true,
    filterType: 'contains',
    type: 'text',
    sortProp: 'field42',
    defaultHidden: true,
  },
  {
    _t_id: '4290ae44',
    prop: 'field43',
    text: 'field43',
    sort: true,
    filter: true,
    filterType: 'contains',
    type: 'text',
    sortProp: 'field43',
    defaultHidden: true,
  },
  {
    _t_id: '4290ae45',
    prop: 'field44',
    text: 'field44',
    sort: true,
    filter: true,
    filterType: 'contains',
    type: 'text',
    sortProp: 'field44',
    defaultHidden: true,
  },
  {
    _t_id: '4290ae46',
    prop: 'field45',
    text: 'field45',
    sort: true,
    filter: true,
    filterType: 'contains',
    type: 'text',
    sortProp: 'field45',
    defaultHidden: true,
  },
  {
    _t_id: '4290ae47',
    prop: 'field46',
    text: 'field46',
    sort: true,
    filter: true,
    filterType: 'contains',
    type: 'text',
    sortProp: 'field46',
    defaultHidden: true,
  },
]

export default {
  components: {
    BaseTableWrapper,
  },
  metaInfo() {
    return {
      title: 'Web Forms',
    }
  },
  data() {
    return {
      isAdmin: true,
      specialTextFormatting: {
        phone: phoneFormatFilter,
      },
      tableInit: {
        enableStatusFilterToggle: false,
        enableColumnConfig: true,
        enableExport: false,
        detailKeyId: 'operatorLeadsId',
        tableId: 'web_form_tv_view',
        addNewEnabled: false,
        currentPage: 1,
        perPage: 10,
        columns,
        collection: 'CharterUpLeads',
        action: (params) => charterUpLeads.getCharterUpLeads(params),
      },
    }
  },
}
</script>
